import React, { Suspense, Component } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import routes from "routes";
import Preview from "pages/Preview";
import Login from "components/Login";
import Loader from "components/Loader";
import Home from "components/Home/index";
import PageNotFound from "pages/PageNotFound";
import AuthCallBack from "components/AuthCallBack";
import { authProvider } from "components/AuthProvider";
import isAdminUserCheck from "components/AdminFIMcheck";
import ConfirmationModal from "components/ConfirmationModal";
import DisplayPDF from "pages/DisplayPDF";

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isAuthenticated: false,
      hasfetchedAuthenticationState: false,
    };
    this.toggleState = this.toggleState.bind(this);
  }

  async componentDidMount() {
    try {
      const isLoggedInUser = await authProvider.isLoggedIn();
      const claims = await authProvider.getIdTokenClaims();
      let grantAdmin = false;


      const path =  window.location.pathname;
      console.log("Added path name console ===============",path);
      const popupRegex = /^\/jp(\/.*)?|\/previewPDF|\/PDFFiles\/.*|\.pdf$/i;
      if (popupRegex.test(path)) {
        this.setState({
          showModal: true
        });
      } else {
        this.setState({
          showModal: true
        });
      }

      // if ( window.location.pathname == "/jp/" || window.location.pathname == "/jp" || window.location.pathname == "/"){
      //   this.setState({
      //     showModal: true
      //   });
      // }

      if (claims) {
        window.sessionStorage.setItem("loggedinUserName", claims.name);
        window.sessionStorage.setItem("userEmailID", claims.username);
        grantAdmin = await isAdminUserCheck();
        if (
          !grantAdmin &&
          window.location.pathname !== "/jp" &&
          window.location.pathname !== "/jp/Unauthorized"
        ) {
          window.location = "/jp/Unauthorized";
        }
        this.setState({
          showModal: true
        });
      }
      this.setState({
        isAuthenticated: grantAdmin,
        hasfetchedAuthenticationState: true,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        hasfetchedAuthenticationState: false,
      });
      throw new Error("Authentication status not available");
    }
  }

  toggleState() {
    this.setState({
      isAuthenticated: true,
    });
  }

  handleYesClick = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { isAuthenticated, hasfetchedAuthenticationState, showModal } =
      this.state;
    if (hasfetchedAuthenticationState) {
      return (
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={() =>
                    isAuthenticated ? (
                      <route.component />
                    ) : (
                      <Login login={authProvider.login} />
                    )
                  }
                  exact={true}
                />
              ))}
              <Route path="/jp" exact={true}>
                <Home />
              </Route>
              <Route path="/jp/news_list.html" exact={true}>
                <Home />
              </Route>
              <Route path="/jp/previewPDF/:param1/:param2" exact={true}>
                <DisplayPDF />
              </Route>
              <Route path="/jp/preview" exact={true}>
                {Preview}
              </Route>
              <Route
                path="/auth/callback"
                render={(props) => (
                  <AuthCallBack
                    history={props.history}
                    toggleState={() => this.toggleState}
                  />
                )}
                exact={true}
              />
              <Route path="/jp/Unauthorized" exact={true}>
                <PageNotFound />
              </Route>
              <Redirect from={"/"} to={"/jp"} exact={true} />
              <Redirect from={"/new"} to={"/jp/new"} exact={true} />
              <Redirect from={"/admin"} to={"/jp/admin"} exact={true} />
              <Redirect from={"/error"} to={"/jp/error"} exact={true} />
              <Redirect
                from={"/news_list.html"}
                to={"/jp/news_list.html"}
                exact={true}
              />
              <Redirect
                from={"/previewPDF/:param1/:param2"}
                to={"/jp/previewPDF/:param1/:param2"}
                exact={true}
              />
              <Route path="*" exact={true}>
                <PageNotFound />
              </Route>
            </Switch>
          </Suspense>
          {showModal && (
            <ConfirmationModal
              show={showModal}
              data-keyboard={false}
              handleClose={() => this.setState({ showModal: false })}
              handleYesClick={this.handleYesClick}
            />
          )}
        </Router>
      );
    }

    return (
      <Loader>
        <div>Hello World!</div>
      </Loader>
    );
  }
}

export default AppRouter;