import React, { useEffect, useState } from 'react';
import configData from "../config.json";
import ConfirmationModal from '../components/ConfirmationModal';

function DisplayPDF() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const getCookie = (name) => {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(name + '=')) {
                    return cookie.substring(name.length + 1);
                }
            }
            return null;
        };

        const myCookieValue = getCookie('confirmationModalShown');
        console.log('===============My Cookie Value===================',myCookieValue);
        setShowModal(!myCookieValue);

        const resizeIframe = () => {
            const iframe = document.querySelector('.pdfIframe');
            if (iframe) {
                iframe.style.height = window.innerHeight + 'px';
            }
        };

        resizeIframe();

        const handleResize = () => {
            resizeIframe();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleYesClick = () => {
        document.cookie = "confirmationModalShown=true;path=/;";
        setShowModal(false);
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <iframe
                src={`${configData.APP_URL}/PDFFiles/${window.location.href.split('/').slice(-2).join('/')}`}
                className="pdfIframe"
                style={{ height: '100%', width: '100%', border: 'none' }}
            />
            {showModal && (
                <ConfirmationModal
                    show={showModal}
                    data-keyboard={false}
                    handleClose={() => setShowModal(false)}
                    handleYesClick={handleYesClick}
                />
            )}
        </div>
    );
}

export default DisplayPDF;
